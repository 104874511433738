import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layout/Layout.vue'
import {Role} from "@/model/server/Role";

Vue.use(Router);

/*
  redirect:                      if `redirect: noredirect`, it won't redirect if click on the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
    // mode: 'history',  // Disabled due to GitHub Pages doesn't support this, enable this if you need.

    // noinspection JSUnusedGlobalSymbols
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: () => import('@/views/login/index.vue'),
            meta: { hidden: true }
        },
        {
            path: '/404',
            component: () => import('@/views/404/index.vue'),
            meta: { hidden: true }
        },
        {
            path: '/',
            component: Layout,
            redirect: '/transactions',
            children: [
                {
                    path: 'transactions',
                    component: () => import('@/views/transactions/index.vue'),
                    name: 'Transactions',
                    meta: { title: 'Transactions', roles: [ Role.transactions ] }
                },
                {
                    path: 'cards',
                    component: () => import('@/views/cardstatistic/index.vue'),
                    name: 'Cards',
                    meta: { title: 'Cards', roles: [ Role.card_statistic ] }
                },
                {
                    path: 'ctl',
                    component: () => import('@/views/customer-trust-indicators/index.vue'),
                    name: 'CTL',
                    meta: { title: 'CTL', roles: [ Role.customer_trust_indicator ] }
                },
                {
                    path: 'rules',
                    component: () => import('@/views/detection/rules/index.vue'),
                    name: 'Rules',
                    meta: { title: 'Rules', roles: [ Role.rules, Role.rules_edit ] }
                },
                {
                    path: 'rules-triggering-panel',
                    component: () => import('@/views/detection/rules-triggering-panel/index.vue'),
                    name: 'Rules triggering panel',
                    meta: { title: 'Rules triggering panel', roles: [ Role.rules_triggering_panel ] }
                },
                {
                    path: 'scoring-limits',
                    component: () => import('@/views/detection/scoring-limits/index.vue'),
                    name: 'Scoring Limits',
                    meta: { title: 'Scoring Limits', roles: [ Role.rules_edit ] }
                },
                {
                    path: 'lists',
                    component: () => import('@/views/detection/index.vue'),
                    name: 'Lists',
                    meta: { title: 'Lists', roles: [ Role.blacklists, Role.autoblocks,
                            Role.whitelists, Role.testlists,Role.custom_lists, Role.custom_lists_edit,
                            Role.detectorlist_notification,Role.customer_trust_indicator_parameters] },
                    children: [
                        {
                            path: 'test-lists',
                            component: () => import('@/views/detection/test-lists/index.vue'),
                            name: 'Test Lists',
                            meta: { title: 'Test Lists', roles: [ Role.testlists ] },
                        },
                        {
                            path: 'black-lists',
                            component: () => import('@/views/detection/black-lists/index.vue'),
                            name: 'Black Lists',
                            meta: { title: 'Black Lists', roles: [ Role.blacklists ] },
                        },
                        {
                            path: 'auto-blocks',
                            component: () => import('@/views/detection/auto-blocks/index.vue'),
                            name: 'Auto Blocks',
                            meta: { title: 'Auto Blocks', roles: [ Role.autoblocks ] }
                        },
                        {
                            path: 'white-lists',
                            component: () => import('@/views/detection/white-lists/index.vue'),
                            name: 'White Lists',
                            meta: { title: 'White Lists', roles: [ Role.whitelists ] }
                        },
                        {
                            path: 'custom-lists',
                            component: () => import('@/views/detection/custom-lists/index.vue'),
                            name: 'Custom Lists',
                            meta: { title: 'Custom Lists', roles: [ Role.custom_lists, Role.custom_lists_edit] }
                        },
                        {
                            path: 'detectorlist-notifications',
                            component: () => import('@/views/notifications/detectorlist-notifications/index.vue'),
                            name: "Detectorlist notification",
                            meta: { title: 'Detectorlist notification', roles: [Role.detectorlist_notification] }
                        },
                        {
                            path: 'trust-indicators-list',
                            component: () => import('@/views/references/trust-indicators-list/index.vue'),
                            name: 'Trust indicators list',
                            meta: { title: 'Trust indicators list', roles: [Role.customer_trust_indicator_parameters] }
                        }
                    ]
                },
                {
                    path: 'reports',
                    component: () => import('@/views/reports/index.vue'),
                    name: 'Reports',
                    meta: { title: 'Reports', roles: [ Role.reports, Role.report_fraud_to_sales_ratio, Role.report_chargebacks_to_transactions_ratio, Role.report_transactions,
                            Role.report_bins, Role.report_chargeback_list, Role.report_country_conversion_rate, Role.report_fca_017, Role.report_fca_017_extended, Role.report_fraud_cases,
                        Role.report_high_velocity_total_amount, Role.report_high_velocity_total_amount_all, Role.report_increase_ratio, Role.report_payment_system_for_cards_amount,
                            Role.report_merchant_monitoring, Role.report_potential_fraud_cases, Role.report_rule_action, Role.report_rules_activation, Role.report_sales_geo_locations,
                        Role.report_transaction_authorizations, Role.report_transaction_list, Role.report_transaction_statuses, Role.report_turnover_merchant_horizontal_analysis,
                        Role.report_unique] }
                },
                {
                    path: 'service',
                    component: () => import('@/views/service/index.vue'),
                    name: 'Service',
                    meta: { title: 'Service', roles: [ Role.tasks, Role.schedule,Role.trends,Role.history,Role.send_test_transactions ] },
                    children:[
                        {
                            path: 'tasks',
                            component: () => import('@/views/service/tasks/index.vue'),
                            name: 'Tasks',
                            meta: { title: 'Tasks', roles: [ Role.tasks ] }
                        },
                        {
                            path: 'schedule',
                            component: () => import('@/views/service/schedule/index.vue'),
                            name: 'Schedule',
                            meta: { title: 'Schedule', roles: [ Role.schedule ] }
                        },
                        {
                            path: 'trends',
                            component: () => import('@/views/service/trends/index.vue'),
                            name: 'Trends',
                            meta: { title: 'Trends', roles: [ Role.trends ] }
                        },
                        {
                            path: 'history',
                            component: () => import('@/views/service/history/index.vue'),
                            name: 'History',
                            meta: { title: 'History', roles: [ Role.history ] }
                        },
                        {
                            path: 'send-test-transaction',
                            component: () => import('@/views/service/send-test-transaction/index.vue'),
                            name: 'Test transaction',
                            meta: { title: 'Test transaction', roles: [ Role.send_test_transactions ] }
                        },
                    ]
                },
                {
                    path: 'management',
                    component: () => import('@/views/merchants/index.vue'),
                    name: 'Management',
                    meta: {title: 'Management', roles: [Role.users, Role.users_edit, Role.gateways, Role.admin]},
                    children: [
                        {
                            path: 'users',
                            component: () => import('@/views/management/users/index.vue'),
                            name: 'Users',
                            meta: { title: 'Users', roles: [ Role.users, Role.users_edit ] }
                        },
                        {
                            path: 'gateways',
                            component: () => import('@/views/management/gateways/index.vue'),
                            name: 'Gateways',
                            meta: { title: 'Gateways', roles: [ Role.gateways ] }
                        },
                        {
                            path: 'tokens',
                            component: () => import('@/views/management/tokens/index.vue'),
                            name: 'Tokens',
                            meta: { title: 'Tokens', roles: [ Role.admin ] }
                        },
                    ]
                },
                {
                    path: 'merchants',
                    component: () => import('@/views/merchants/index.vue'),
                    name: 'Merchants',
                    meta: { title: 'Merchants', roles: [ Role.merchants, Role.merchants_statistic ] },
                    children: [
                        {
                        path: 'merchants-list',
                        component: () => import('@/views/merchants/merchants-list/index.vue'),
                        name: 'MerchantsList',
                        meta: { title: 'Merchants', roles: [ Role.merchants ] }
                        },
                        {
                            path: 'references-list',
                            component: () => import('@/views/merchants/references-list/index.vue'),
                            name: 'ReferencesList',
                            meta: { title: 'References', roles: [ Role.merchants ] }
                        },
                        {
                            path: 'merchants-statistic',
                            component: () => import('@/views/merchants/merchants-statistic/index.vue'),
                            name: 'MerchantsStatistic',
                            meta: { title: 'Merchants statistic', roles: [ Role.merchants_statistic ] }
                        },
                    ]
                },
                {
                    path: 'fraud',
                    component: () => import('@/views/fraud/index.vue'),
                    name: 'Fraud',
                    meta: { title: 'Fraud', roles: [ Role.fraud_manager ] },
                    children: [
                        {
                            path: 'marker',
                            component: () => import('@/views/fraud/marker/index.vue'),
                            name: 'Marker',
                            meta: { title: 'Marker', roles: [ Role.fraud_manager ] },
                        },
                        {
                            path: 'fraud_cases',
                            component: () => import('@/views/fraud/cases/index.vue'),
                            name: 'Fraud_cases',
                            meta: { title: 'Reported Fraud Cases', roles: [ Role.fraud_manager ] },
                        },
                        {
                            path: 'potential_fraud_cases',
                            component: () => import('@/views/fraud/pftcases/index.vue'),
                            name: 'Potential_fraud_cases',
                            meta: { title: 'Potential Fraud Cases', roles: [ Role.fraud_manager ] },
                        },
                        {
                            path: 'statistic',
                            component: () => import('@/views/fraud/statistic/index.vue'),
                            name: 'Fraud_statistic',
                            meta: { title: 'Reported Fraud Statistic', roles: [ Role.fraud_manager ] },
                            children:[
                                {
                                    path: 'overview',
                                    component: () => import('@/views/fraud/statistic/overview/index.vue'),
                                    name: 'Fraud_statistic_overview',
                                    meta: { title: 'Overview', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'geographical_breakdown',
                                    component: () => import('@/views/fraud/statistic/geographical-breakdown/index.vue'),
                                    name: 'Fraud_statistic_geographical_breakdown',
                                    meta: { title: 'Geo breakdown', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'countries',
                                    component: () => import('@/views/fraud/statistic/countries/index.vue'),
                                    name: 'Fraud_statistic_countries',
                                    meta: { title: 'Countries', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'bins',
                                    component: () => import('@/views/fraud/statistic/bins/index.vue'),
                                    name: 'Fraud_statistic_bins',
                                    meta: { title: 'BINs', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'fraud_type',
                                    component: () => import('@/views/fraud/statistic/fraud-type/index.vue'),
                                    name: 'Fraud_statistic_fraud_type',
                                    meta: { title: 'Fraud type', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'card_type',
                                    component: () => import('@/views/fraud/statistic/card-type/index.vue'),
                                    name: 'Fraud_statistic_card_type',
                                    meta: { title: 'Card type', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'merchants_fraud',
                                    component: () => import('@/views/fraud/statistic/merchants/index.vue'),
                                    name: 'Fraud_statistic_merchants',
                                    meta: { title: 'Merchants', roles: [ Role.fraud_manager ] },
                                },
                                {
                                    path: 'fraud_detection_rate',
                                    component: () => import('@/views/fraud/statistic/detection-rate/index.vue'),
                                    name: 'Fraud_detection_rate',
                                    meta: { title: 'Detection rate', roles: [ Role.fraud_manager ] },
                                },
                            ]
                        },
                    ]
                },
                {
                    path: 'chargebacks',
                    component: () => import('@/views/chargebacks/index.vue'),
                    name: 'Chargebacks',
                    meta: { title: 'Chargebacks', roles: [ Role.chargebacks ] },
                    children:[
                        {
                            path: 'cases',
                            component: () => import('@/views/chargebacks/cases/index.vue'),
                            name: 'Chargeback_cases',
                            meta: { title: 'Cases', roles: [ Role.chargebacks ] },
                        },
                        {
                            path: 'statistic',
                            component: () => import('@/views/chargebacks/index.vue'),
                            name: 'Statistic',
                            meta: { title: 'Statistic', roles: [ Role.chargebacks ] },
                            children:[
                                {
                                    path: 'overview',
                                    component: () => import('@/views/chargebacks/statistic/overview/index.vue'),
                                    name: 'Chargeback_statistic_overview',
                                    meta: { title: 'Overview', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'countries',
                                    component: () => import('@/views/chargebacks/statistic/countries/index.vue'),
                                    name: 'Chargeback_statistic_countries',
                                    meta: { title: 'Countries', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'bins',
                                    component: () => import('@/views/chargebacks/statistic/bins/index.vue'),
                                    name: 'Chargeback_statistic_bins',
                                    meta: { title: 'BINs', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'rc',
                                    component: () => import('@/views/chargebacks/statistic/rc/index.vue'),
                                    name: 'Chargeback_statistic_rc',
                                    meta: { title: 'Reason Codes', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'source',
                                    component: () => import('@/views/chargebacks/statistic/source/index.vue'),
                                    name: 'Chargeback_statistic_source',
                                    meta: { title: 'Source', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'results',
                                    component: () => import('@/views/chargebacks/statistic/results/index.vue'),
                                    name: 'Chargeback_statistic_results',
                                    meta: { title: 'Results', roles: [ Role.chargebacks ] },
                                },
                                {
                                    path: 'merchants_chargeback',
                                    component: () => import('@/views/chargebacks/statistic/merchants/index.vue'),
                                    name: 'Chargeback_statistic_merchants',
                                    meta: { title: 'Merchants', roles: [ Role.chargebacks ] },
                                },
                                ]
                        },
                    ]
                },

                {
                    path: 'report-limits',
                    component: () => import('@/views/report-limits/index.vue'),
                    name: 'Report Limits',
                    meta: { title: 'Report Limits', roles: [ Role.report_limits, Role.report_limits_edit ] },
                    children:[
                        {
                            path: 'hvta-limits',
                            component: () => import('@/views/report-limits/hvta/index.vue'),
                            name: 'HVTA Limits',
                            meta: { title: 'HVTA Limits', roles: [ Role.report_limits, Role.report_limits_edit ] },
                        },
                        {
                            path: 'ps-limits',
                            component: () => import('@/views/report-limits/ps-limits/index.vue'),
                            name: 'PS Limits',
                            meta: { title: 'PS Limits', roles: [ Role.report_limits, Role.report_limits_edit ] },
                        },
                        {
                            path: 'reference-limits',
                            component: () => import('@/views/report-limits/reference-limits/index.vue'),
                            name: 'Reference Limits',
                            meta: { title: 'Reference Limits', roles: [ Role.report_limits, Role.report_limits_edit ] },
                        },
                    ]
                },
                {
                    path: 'references',
                    component: () => import('@/views/references/index.vue'),
                    name: 'References',
                    meta: { title: 'References', roles: [ Role.reference, Role.reference_edit ] },
                    children: [
                        {
                            path: 'countries-list',
                            component: () => import('@/views/references/countries-list/index.vue'),
                            name: 'Countries list',
                            meta: { title: 'Countries list', roles: [ Role.reference, Role.reference_edit ] }
                        },
                        {
                            path: 'countries-phone-codes-list',
                            component: () => import('@/views/references/countries-phone-codes-list/index.vue'),
                            name: 'Phone codes list',
                            meta: { title: 'Phone codes list', roles: [ Role.reference, Role.reference_edit ] }
                        },

                    ]
                },
                {
                    path: 'maintenance',
                    component: () => import('@/views/maintenance/index.vue'),
                    name: 'Maintenance',
                    meta: { title: 'Maintenance', roles: [ Role.admin, Role.db_loader] },
                    children:[
                        {
                            path: 'trends',
                            component: () => import('@/views/maintenance/trends/index.vue'),
                            name: 'Admin trends',
                            meta: { title: 'Trends', roles: [ Role.admin ] },
                        },
                        {
                            path: 'load-files',
                            component: () => import('@/views/maintenance/load-files/index.vue'),
                            name: 'Load DB files',
                            meta: { title: 'Load DB files', roles: [ Role.db_loader ] },
                        },
                        {
                            path: 'test-rules',
                            component: () => import('@/views/maintenance/test-rules/index.vue'),
                            name: 'Test rules',
                            meta: { title: 'Test rules', roles: [ Role.admin ] },
                        }
                    ]
                },
                {
                    path: 'transaction',
                    component: () => import('@/views/transaction/index.vue'),
                    name: 'Transaction',
                    meta: { title: 'Transaction', hidden: true, roles: [Role.transactions] }
                },
                {
                    path: 'rule',
                    component: () => import('@/views/rule/index.vue'),
                    name: 'Rule',
                    meta: { title: 'Rule', hidden: true, roles: [Role.transactions] }
                },
                {
                    path: 'stub',
                    component: () => import('@/views/stub/index.vue'),
                    name: 'Stub',
                    meta: { title: '', hidden: true, roles: [] }
                },
                {
                    path: 'object-history',
                    component: () => import('@/views/object-history/index.vue'),
                    name: 'ObjectHistory',
                    meta: { title: 'History', hidden: true, roles: [ Role.history ] }
                },
                {
                    path: 'profile',
                    component: () => import('@/views/profile/index.vue'),
                    name: 'Profile',
                    meta: { title: 'Profile', hidden: false, roles: [] }
                },
            ]
        },
        {
            path: '*',
            redirect: '/404',
            meta: { hidden: true }
        },
    ]
})
