import {IRequester} from "@/model/ServerApi";
import {Currencies} from "@/model/server/Currencies";
import {ReportHVTALimit} from "@/model/server/limits/ReportHVTALimit";
import {SendFileResponse} from "@/model/bindings/SendFileResponse";
import {SendFileRequest} from "@/model/bindings/SendFileRequest";
import {ReportPSLimit} from "@/model/server/limits/ReportPSLimit";
import {ReportReferenceLimit} from "@/model/server/limits/ReportReferenceLimit";
import {Reference} from "@/model/server/Reference";
import {ReferenceResponse} from "@/model/bindings/ReferenceResponse";
import {TestRuleResult} from "@/model/server/TestRuleResult";

export class CommonServerApi {
    private cachedCurrencies: Promise<Currencies>;
    private cachedPaymentSystems: { [gateway: number]: Promise<string[]> } = {};
    private cachedEEACountries: Promise<string[]>;
    private cachedAllCountries: Promise<string[]>;

    constructor(private requester: IRequester) {
    }

    getEEACountries(): Promise<string[]> {
        return this.cachedEEACountries ?
            this.cachedEEACountries
            : (this.cachedEEACountries = this.requester.get('/common/eeacountries'));
    }
    getAllCountries(): Promise<string[]> {
        return this.cachedAllCountries ?
            this.cachedAllCountries
            : (this.cachedAllCountries = this.requester.get('/common/allcountries'));
    }

    getCurrencies(): Promise<Currencies> {
        return this.cachedCurrencies
            ? this.cachedCurrencies
            : (this.cachedCurrencies = this.requester.get('/common/currencies'));
    }

    async getPaymentSystems(gateway: number): Promise<string[]> {
        return this.cachedPaymentSystems[gateway]
            ? this.cachedPaymentSystems[gateway]
            : (this.cachedPaymentSystems[gateway] = this.requester.get('/common/paymentsystems?gateway=' + gateway));
    }
    getRuleIds(): Promise<number[]> {
        return this.requester.get('/common/getrulesids');
    }
    testRule(id:number): Promise<TestRuleResult>
    {
        return this.requester.get('/common/testrule?id='+id);
    }
    getHVTALimits(): Promise<ReportHVTALimit[]> {
        return this.requester.get('/common/gethvtalimits');
    }

    delHVTALimit(id: number): Promise<boolean> {
        return this.requester.get('/common/deletehvtalimit?id=' + id);
    }

    modifyHVTALimit(arg: ReportHVTALimit): Promise<boolean> {
        return this.requester.post('/common/modifyhvtalimit', arg);
    }

    getPSLimits(): Promise<ReportPSLimit[]> {
        return this.requester.get('/common/getpslimits');
    }

    delPSLimit(id: number): Promise<boolean> {
        return this.requester.get('/common/deletepslimit?id=' + id);
    }

    modifyPSLimit(arg: ReportPSLimit): Promise<boolean> {
        return this.requester.post('/common/modifypslimit', arg);
    }
    getReferenceLimits(): Promise<ReportReferenceLimit[]> {
        return this.requester.get('/common/getreferencelimits');
    }

    delReferenceLimit(id: number): Promise<boolean> {
        return this.requester.get('/common/deletereferencelimit?id=' + id);
    }

    modifyReferenceLimit(arg: ReportReferenceLimit): Promise<boolean> {
        return this.requester.post('/common/modifyreferencelimit', arg);
    }

    sendIPs(arg: SendFileRequest): Promise<SendFileResponse> {
        return this.requester.post('/common/sendips', arg);
    }

    sendBINs(arg: SendFileRequest): Promise<SendFileResponse> {
        return this.requester.post('/common/sendbins', arg);
    }

    getFullPAN(transactionId: string): Promise<string> {
        return this.requester.post('/common/getfullpan?id=' + transactionId);
    }
    getReferences(): Promise<ReferenceResponse>{
        return this.requester.post('/common/getreferences');
    }
    saveReference(ref: Reference): Promise<Reference>{
        return this.requester.post('/common/savereference', ref);
    }
    getAllReferences(): Promise<Reference[]>{
        return this.requester.post('/common/getallreferences');
    }
}
