export enum Role {
    admin = 'admin',
    transactions = 'transactions',
    transaction_details = 'transaction_details',
    merchants_statistic = 'merchants_statistic',
    merchants = 'merchants',
    merchant_manager = 'merchant_manager',
    users = 'users',
    gateways = 'gateways',
    send_test_transactions = 'send_test_transactions',
    tasks = 'tasks',
    db_loader = 'db_loader',
    schedule = 'schedule',
    reports = 'reports',
    fraud_manager = 'fraud_manager',
    trends = 'trends',
    history = 'history',
    users_edit = 'users_edit',
    custom_lists = 'custom_lists',
    custom_lists_edit = 'custom_lists_edit',
    rules = 'rules',
    rules_edit = 'rules_edit',
    rules_triggering_panel = 'rules_triggering_panel',
    testlists = 'testlists',
    testlists_edit = 'testlists_edit',
    blacklists = 'blacklists',
    blacklists_edit = 'blacklists_edit',
    blacklists_edit_protected = 'blacklists_edit_protected',
    autoblocks = 'autoblocks',
    autoblocks_edit = 'autoblocks_edit',
    whitelists = 'whitelists',
    whitelists_edit = 'whitelists_edit',
    detectorlist_notification = 'detectorlist_notification',
    chargebacks = 'chargebacks',
    chargebacks_edit = 'chargebacks_edit',
    report_limits = 'report_limits',
    report_limits_edit = 'report_limits_edit',
    reference_edit = 'reference_edit',
    reference = 'reference',
    card_statistic = 'card_statistic',
    customer_trust_indicator = 'customer_trust_indicator',
    customer_trust_indicator_parameters = 'customer_trust_indicator_parameters',

    report_fraud_to_sales_ratio = 'report_fraud_to_sales_ratio',
    report_chargebacks_to_transactions_ratio = 'report_chargebacks_to_transactions_ratio',
    report_transactions = 'report_transactions',
    report_rules_activation = 'report_rules_activation',
    report_fraud_cases = 'report_fraud_cases',
    report_potential_fraud_cases = 'report_potential_fraud_cases',
    report_transaction_list = 'report_transaction_list',
    report_unique = 'report_unique',
    report_bins = 'report_bins',
    report_transaction_statuses = 'report_transaction_statuses',
    report_rule_action = 'report_rule_action',
    report_transaction_authorizations = 'report_transaction_authorizations',
    report_fca_017 = 'report_fca_017',
    report_fca_017_extended = 'report_fca_017_extended',
    report_merchant_monitoring = 'report_merchant_monitoring',
    report_increase_ratio = 'report_increase_ratio',
    report_high_velocity_total_amount = 'report_high_velocity_total_amount',
    report_high_velocity_total_amount_all = 'report_high_velocity_total_amount_all',
    report_chargeback_list = 'report_chargeback_list',
    report_sales_geo_locations = 'report_sales_geo_locations',
    report_country_conversion_rate = 'report_country_conversion_rate',
    report_payment_system_for_cards_amount = 'report_payment_system_for_cards_amount',
    report_turnover_merchant_horizontal_analysis = 'report_turnover_merchant_horizontal_analysis',
    report_detectorlist_trigger_cases = 'report_detectorlist_trigger_cases',
    report_processing_history = 'report_processing_history',
}

export class RoleTools {
    public static readonly ALL = Object.keys(Role).sort();
}
