import enLocale from 'element-ui/lib/locale/lang/en'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'

export const messages =
{
    ru: {
        firstDayOfWeek: '1',

        'Bank': 'Банк',
        'Period': 'Период',
        'Pick a date': 'Выберите дату',
        'Refresh': 'Обновить',
        'Amount': 'Сумма',
        'Transactions': 'Транзакции',
        'Transaction': 'Транзакция',
        'Rules': 'Правила',
        'Rule': 'Правило',
        'Lists': 'Списки',
        'Tasks':'Задачи',
        'Schedule':'Расписание',
        'Task':'Задача',
        'Reports':'Отчеты',
        'Report':'Отчет',
        'Query time':'Время запроса',
        'Start time':'Время запуска',
        'Ready time':'Время готовности',
        'Kill time':'Время удаления',
        'Link':'Ссылка',
        'Type': 'Тип',
        'Status': 'Статус',
        'List': 'Список',
        'Gateway': 'Плат. шлюз',
        'Gateways': 'Плат. шлюзы',
        'Blacklist entry':'Блокированый элемент',
        'Blacklist report':'Отчет по блокировкам',
        'Merchant': 'Мерчант',
        'Merchants': 'Мерчанты',
        'Description': 'Описание',
        'Field': 'Поле',
        'Name': 'Название',
        'Value': 'Значение',
        'Edit': 'Изменить',
        'Remove': 'Удалить',
        'Save': 'Сохранить',
        'Cancel': 'Отмена',
        'Fraud': 'Фрод',
        'Fraud?': 'Фрод?',
        'Blacklists': 'Списки блокировок',
        'Yes': 'Да',
        'No': 'Нет',
        'Score': 'Оценка',
        'Action': 'Действие',
        'Language': 'Язык',
        'Password': 'Пароль',
        'User': 'Пользователь',
        'Users': 'Пользователи',
        'Test transaction': 'Тестовая транзакция',
        'Maintenance':'Обслуживание',
        'History':'История',
        'Send': 'Отправить',
        'and': 'и',
        'Start date must be set': 'Должна быть задана начальная дата',
        'Request accepted': 'Запрос принят',
        'Something went wrong': 'Что-то пошло не так',
        'Transaction information': 'Данные по транзакциям',
        'Create period': 'Период создания',
        'Remove period': 'Период удаления',
        'Trends': 'Тренды',
        'Tokens':'Токены',
        'Test?': 'Тестовая?',
        'Currency': 'Валюта',
        'Order ID': 'ID заказа',
        'Txn ID in gateway': 'ID тран. в системе шлюза',
        'ECI code': 'ECI-код',
        'Card network': 'ПС карты',
        'Card type': 'Тип карты',
        'Prepaid card?': 'Предопл. карта?',
        'Corporate/business card?': 'Корпорат./бизнес карта?',
        'Virtual card?': 'Виртуальная карта?',
        'Txn type': 'Тип транзакции',
        'Detection&Prevention': 'Детектирование',
        'PS': 'ПС',
        'Payment System': 'Платёжная система',
        'Trend': 'Тренд',
        'Close': 'Закрыть',
        'Upload data': 'Загрузить данные',
        'Data for trends': 'Данные для трендов',
        'Additional': 'Дополнительно',
        'Start date must not be greater than end date': 'Начальная дата не должна превышать конечную',
        'Start date must not be in the future': 'Начальная дата не должна лежать в будущем',
        'Chargebacks':'Чарджбеки',
        'Cases':'Кейсы',
        'Statistic':'Статистика',

        'Compliance Lists': 'Комплайнс списки',
        'Black Lists': 'Чёрные списки',
        'Auto Blocks': 'Автоблокировки',
        'PS Limits': 'Лимиты ПС',
        'Merchant Limits': 'Лимиты мерчантов',
        'White Lists': 'Белые списки',
        'Custom Lists': 'Универс. списки',
        'White Rules': 'Белые правила',
        'Hard Block Logic': 'Жёсткие правила',
        'Soft Block Logic': 'Правила скоринга',
        'Scoring Limits': 'Лимиты скоринга',
        'Report Limits': 'Лимиты отчетов',
        'HVTA Limits': 'Лимиты отчета HVTA',

        // transactions
        'Reported fraud?': 'Сообщение о фроде?',
        'Wallet account': 'Счет кошелька',
        'Customer e-mail': 'Электронная почта клиента',
        'Customer IP': 'IP-адрес клиента',
        'Analyze duration, ms': 'Длительность анализа, ms',
        'Reported fraud receipt date': 'Дата получения сообщения о фроде',
        'Reported fraud type code': 'Тип кода сообщения о фроде',
        'Potential fraud?': 'Потенциальный фрод?',
        'Potential fraud decision date': 'Дата принятия решения о потенциальном фроде',
        'Potential fraud decision': 'Решение о потенциальном фроде',
        'Card check score': 'Показатель оценки карты',
        'Customer Trust Level': 'Уровень доверия клиента',
        'Customer ID': 'ID Клиента',
        'Phone number': 'Телефонный номер клиента',
        'Reason code': 'Код причины',
        'Reason message': 'Текст причины',
        'Customer trust indicator': 'Индикатор доверия клиенту',
        'Customer phone number': 'Номер телефона клиента',
        'Country phone code': 'Телефонный код страны',

        //transaction
        'Country phone number': 'Страна номера телефона',
        'PS information': 'ПС информация',
        'Potential fraud': 'Потенциальный фрод',
        'Payment instrument type': 'Тип платежного инструмента',
        'Transaction type': 'Тип транзакции',
        'Rule ID': 'ID правила',

        //rules
        'Rule created': 'Правило создано',
        'Priority' : 'Приоритет',
        'Rule description': 'Описание правила',
        'Block reason message': 'Причина блокировки',
        'Rule expression': 'Текст правила',

        'Wallet': 'Кошелек',
        'Country Code': 'Код страны',
        'Allow CTI calculation': 'Разрешен расчет CTI',

        //users
        'Autoblock?': 'Автоблокировка?',
        'Last login': 'Последняя авторизация',
        'Password changed': 'Пароль изменен',

        //gateways
        'Calculate CTI?': 'Разрешен рассчет CTI?',
        'Allow get card statistic': 'Разрешено получать статистику карт',

        //fraud
        'Upload a file contains transactions data like': 'Загрузите файл, содержащий данные транзакций такие, как',
        'Blacklist fraud transactions': 'Черный список фродовых транзакций',
        'Massive "potential fraud" mark changer': 'Массированное изменение отметок "потенциальный фрод"',
        'Mark all transactions in list as potential fraud': 'Отметить все транзакции списка как потенциальный ' +
            'фрод',
        'Unmark all transactions in list as potential fraud': 'Снять отметки о потенциальном фроде со всех ' +
            'транзакций в списке',
        'Transaction Period': 'Период совершения транзакции',
        'Fraud Period': 'Период отметки фродом',
        'Potential Fraud Period': 'Период отметки потенциальным фродом',

        'Overview of card fraud (EUR)': 'Обзор фродов по картам (EUR)',
        'Overview of card fraud (EUR) Mastercard & Maestro': 'Обзор фродов по картам (EUR) Mastercard & Maestro',
        'Overview of card fraud (EUR) Visa': 'Обзор фродов по картам (EUR) Visa',
        'Total value of fraudulent transaction geographical breakdown': 'Общая стоимость фрод транзакций ' +
            'с географической разбивкой',
        'Location': 'Местоположение',
        'Fraud volume': 'Количество фрода',
        'Total value of fraudulent transactions with cards issued in a specific country': 'Общая стоимость ' +
            'фрод операций с картами, выпущенными в конкретной стране',
        'Total value of fraudulent transactions with BINs': 'Общая стоимость фрод операций с БИН',
        'BIN': 'БИН',
        'Total value of card fraud type codes': 'Общая стоимость фрод операций по кодам типов',
        'Total value of fraudulent transactions by card type & products': 'Общая стоимость фрод ' +
            'операций по типам карт и продуктам',
        'Total value of fraudulent transactions by merchants': 'Общая стоимость фрод операций по мерчантам',
        'Fraudulent transactions detection rate': 'Уровень обнаружения фродовых транзакций',
        'By value': 'По стоимости',
        'By volume': 'По количеству',
        'Prepaid': 'Предоплата',
        'Volume PF': 'Количество потенциальных фродов',
        'Volume RF': 'Количество подтвержденных фродов',
        'Value PF, EUR': 'Стоимость потенциальных фродов, EUR',
        'Value RF, EUR': 'Стоимость подтвержденных фродов, EUR',

        //statistics
        'Period type': 'Тип периода',
        'Transaction Type / Status / Verdict / Country': 'Тип транзакции / Статус / Вердикт / Страна',
        'Changes': 'Изменения',

        'Payment system': 'ПС',
        'Limit': 'Лимит',
        'Add limit': 'Добавить лимит',
        'Recalculate data for trends': 'Пересчитать данные трендов',

        //profile
        'Logged in user': 'Авторизованы как',
        'Change password...': 'Изменить пароль...',
        'Password expired': 'Срок действия пароля истек',

        //chargebacks
        'Total value of chargeback transactions with cards issued in a specific country': '' +
            'Общая сумма транзакций с возвратом платежей по картам, выпущенным в конкретной стране',
        'Overview of chargebacks (EUR)': 'Обзор возвратных платежей (EUR)',
        'Overview of chargebacks (EUR) Mastercard & Maestro': 'Обзор возвратных платежей (EUR) Mastercard & Maestro',
        'Overview of chargebacks (EUR) Visa': 'Обзор возвратных платежей (EUR) Visa',
        'Overview of chargebacks (EUR) Mir': 'Обзор возвратных платежей (EUR) МИР',
        'Total value of chargebacks with BINs': 'Общая стоимость возвратных платежей по БИН',
        'Reason Codes (RC) and Categories': 'Коды Причин (RC) и Категории',
        'Proceed from Retrieval Requests & Reported Fraud': 'Образовались из возвратов и фрода',
        'Response Results': 'Результаты ответа',
        'Total number of cases by merchants': 'Общее количество случаев по мерчантам',
        'Comment': 'Комментарий',
        'Chargeback receipt date': 'Дата получения возвратного платежа',
        'Category': 'Категория',
        'Result': 'Результат',

        //diagram&table
        'Volume': 'Объем',
        'Country': 'Страна',
        'Cards': 'Карты',
        'Total cards': 'Количество карт',
        'Total cases': 'Количество случаев',

        //router.ts
        'Marker': 'Пометка',
        'Reported Fraud Cases': 'Зарегистрированные случаи фрод',
        'Potential Fraud Cases': 'Потенциальный фрод',
        'Reported Fraud Statistic': 'Статистика фрод',
        'Merchants statistic': 'Статистика по мерчантам',
        'Trends recalculation': 'Пересчет трендов',
        'Profile': 'Профиль',
        'Overview': 'Обзор',
        'Countries': 'Страны',
        'BINs': 'БИНы',
        'Reason Codes': 'Коды причин',
        'Source': 'Источник',
        'Results': 'Результаты',
        'Geo breakdown': 'Гео разбивка',
        'Fraud type': 'Тип фрода',
        'Detection rate': 'Уровень обнаружения',
        'References': 'Справочники',
        'Countries list': 'Справочник стран',
        'Phone codes list': 'Справочник телефонных кодов стран',

        //forms
        'Apply to payment instrument': 'Применить к платежному инструменту',
        'Block Customer ID': 'Блокировать ID покупателя',
        'Deposit limit': 'Лимит депозита',
        'Credit limit': 'Лимит кредита',
        'Total limit': 'Общий лимит',

        'ACTION_TYPE_ALLOW': 'Пропустить',
        'ACTION_TYPE_VERIFY': 'Проверить',
        'ACTION_TYPE_PENDING': 'Приостановить',
        'ACTION_TYPE_BLOCK': 'Заблокировать',
        'ACTION_TYPE_NOT_CHECKED': 'Не проверялось',

        'TRANSACTION_TYPE_DEPOSIT': 'Пополнение',
        'TRANSACTION_TYPE_CREDIT': 'Выплата',
        'TRANSACTION_TYPE_REFUND': 'Возврат',
        //'TRANSACTION_TYPE_CHARGEBACK': 'Чарджбек',
        'TRANSACTION_TYPE_AUTHORIZATION': 'Авторизация',
        'TRANSACTION_TYPE_CAPTURE': 'Захват',
        'TRANSACTION_TYPE_VOID': 'Отмена',

        'TRANSACTION_STATUS_SUCCESS': 'Проведена',
        'TRANSACTION_STATUS_DECLINE': 'Отклонена',
        'TRANSACTION_STATUS_PENDING': 'В ожидании',
        'TRANSACTION_STATUS_CREATED': 'Создана',
        'TRANSACTION_STATUS_RATED': 'Оценена',

        'PAYMENT_INSTRUMENT_TYPE_CARD': 'Карта',
        'PAYMENT_INSTRUMENT_TYPE_WALLET': 'Кошелёк',

        'REPORT_STATUS_TYPE_READY':'Сформирован',
        'REPORT_STATUS_TYPE_QUEUE':'В очереди',
        'REPORT_STATUS_TYPE_PROCESSING':'Обрабатывается',
        'REPORT_STATUS_TYPE_ERROR':'Ошибка',
        'REPORT_STATUS_TYPE_CANCELING':'Отменяется',
        'REPORT_STATUS_TYPE_CANCELED':'Отменен',
        'REPORT_LINK':'Ссылка',

        'TREND_TYPE_TRANSACTION_AVERAGE_AMOUNT': 'Средний чек по транзакциям',
        'TREND_TYPE_TRANSACTION_TOTAL_AMOUNT': 'Сумма по транзакциям',
        'TREND_TYPE_TRANSACTION_TOTAL_COUNT': 'Количество транзакций',
        'TREND_TYPE_TRANSACTION_SEND_PER_MINUTE_COUNT': 'Кол-во входящих транзакций',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_AFTER_FINAL_PER_MINUTE_COUNT':'Кол-во обновлений статуса после финального',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_FINAL_PER_MINUTE_COUNT':'Кол-во обновлений статуса до финального',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_INTERMEDIATE_PER_MINUTE_COUNT':'Кол-во обновлений статуса до промежуточного',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_PER_MINUTE_COUNT':'Кол-во обновлений статуса всего',
        'TREND_TYPE_TRANSACTION_GET_PER_MINUTE_COUNT':'Кол-во запросов транзакций',
        'TREND_TYPE_TRANSACTION_FRAUD_PER_MINUTE_COUNT':'Кол-во фрод запросов',
        'TREND_TYPE_ECHO_PER_MINUTE_COUNT':'Кол-во эхо запросов',
        'TREND_TYPE_INCOMING_PER_MINUTE_COUNT':'Кол-во запросов всего',
        'TREND_TYPE_CARD_STATISTIC_PER_MINUTE_COUNT':'Кол-во запросов статистики по картам',

        'RULE_STATUS_ACTIVE': 'Активно',
        'RULE_STATUS_TEST': 'Тестовое',
        'RULE_STATUS_DISABLED': 'Отключено',

        "POTENTIAL_FRAUD_DECISION_DETECTED_FRAUD": "Подтвержденный фрод",
        "POTENTIAL_FRAUD_DECISION_LEGITIMATE": "Не фрод",
        "POTENTIAL_FRAUD_DECISION_INVESTIGATION": "Расследование",

        'CUSTOMER_VERIFICATION_LEVEL_BASIC':'Basic',
        'CUSTOMER_VERIFICATION_LEVEL_REGULAR':'Regular',
        'CUSTOMER_VERIFICATION_LEVEL_ADVANCED':'Advanced',

        ...ruLocale
    },
    en: {
        firstDayOfWeek: '1',

        'ACTION_TYPE_ALLOW': 'Allow',
        'ACTION_TYPE_VERIFY': 'Verify',
        'ACTION_TYPE_PENDING': 'Pending',
        'ACTION_TYPE_BLOCK': 'Block',
        'ACTION_TYPE_NOT_CHECKED': 'Not checked',

        'TRANSACTION_TYPE_DEPOSIT': 'Deposit',
        'TRANSACTION_TYPE_CREDIT': 'Credit',
        'TRANSACTION_TYPE_REFUND': 'Refund',
        'TRANSACTION_TYPE_AUTHORIZATION': 'Authorization',
        'TRANSACTION_TYPE_CAPTURE': 'Capture',
        'TRANSACTION_TYPE_VOID': 'Void',

        'TRANSACTION_STATUS_SUCCESS': 'Success',
        'TRANSACTION_STATUS_DECLINE': 'Decline',
        'TRANSACTION_STATUS_PENDING': 'Pending',
        'TRANSACTION_STATUS_CREATED': 'Created',
        'TRANSACTION_STATUS_RATED': 'Rated',

        'PAYMENT_INSTRUMENT_TYPE_CARD': 'Card',
        'PAYMENT_INSTRUMENT_TYPE_WALLET': 'Wallet',

        'REPORT_STATUS_TYPE_READY':'Ready',
        'REPORT_STATUS_TYPE_QUEUE':'In queue',
        'REPORT_STATUS_TYPE_PROCESSING':'Processing',
        'REPORT_STATUS_TYPE_ERROR':'Error',
        'REPORT_STATUS_TYPE_CANCELING':'Canceling',
        'REPORT_STATUS_TYPE_CANCELED':'Canceled',

        'REPORT_LINK':'Link',

        'TREND_TYPE_TRANSACTION_AVERAGE_AMOUNT': 'Transactions average amount',
        'TREND_TYPE_TRANSACTION_TOTAL_AMOUNT': 'Transactions total amount',
        'TREND_TYPE_TRANSACTION_TOTAL_COUNT': 'Transactions total count',
        'TREND_TYPE_TRANSACTION_SEND_PER_MINUTE_COUNT': 'Incoming transactions',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_AFTER_FINAL_PER_MINUTE_COUNT':'Status updates after final',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_FINAL_PER_MINUTE_COUNT':'Status updates final',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_INTERMEDIATE_PER_MINUTE_COUNT':'Status update intermediate',
        'TREND_TYPE_TRANSACTION_UPDATE_STATUS_PER_MINUTE_COUNT':'Status updates total',
        'TREND_TYPE_TRANSACTION_GET_PER_MINUTE_COUNT':'Get transaction details',
        'TREND_TYPE_TRANSACTION_FRAUD_PER_MINUTE_COUNT':'Incoming fraud requests',
        'TREND_TYPE_ECHO_PER_MINUTE_COUNT':'Incoming echo requests',
        'TREND_TYPE_INCOMING_PER_MINUTE_COUNT':'Total incoming requests',
        'TREND_TYPE_CARD_STATISTIC_PER_MINUTE_COUNT':'Incoming card statistic requests',

        'RULE_STATUS_ACTIVE': 'Active',
        'RULE_STATUS_TEST': 'Test',
        'RULE_STATUS_DISABLED': 'Disabled',

        "POTENTIAL_FRAUD_DECISION_DETECTED_FRAUD": "Detected fraud",
        "POTENTIAL_FRAUD_DECISION_LEGITIMATE": "Legitimate",
        "POTENTIAL_FRAUD_DECISION_INVESTIGATION": "Investigation",

        'CUSTOMER_VERIFICATION_LEVEL_BASIC':'Basic',
        'CUSTOMER_VERIFICATION_LEVEL_REGULAR':'Regular',
        'CUSTOMER_VERIFICATION_LEVEL_ADVANCED':'Advanced',

        ...enLocale
    }
};
